import React from "react";

import GridRow from "../../commons/GridRow";
import Button from "../../commons/Button";
import Heading from "../../commons/Heading";
import Box from "../../commons/Box";
import Image from "../../commons/Image";

import { linkResolver } from "../../../utils/links";

export default function ImageListBlock({
  title,
  subtitle,
  buttons,
  style,
  images,
  ...otherProps
}) {
  const hasDescription = title && subtitle && buttons;
  return (
    <GridRow
      as="section"
      sx={{
        py: [5, "gridGap"],
        textAlign: ["center", "start"],
        backgroundColor: "black",
        color: "inverted",
      }}
    >
      {hasDescription && (
        <GridRow.Col gridColumn={["start / end", "start / span 5"]}>
          <Heading
            variant="h2"
            sx={{
              px: [2, 0],
              mt: [4, 0],
              mb: [4, 5],
            }}
          >
            {title}
          </Heading>
          <Heading variant="h3" sx={{}}>
            {subtitle}
          </Heading>
          <Box sx={{ mt: 5 }}>
            {buttons.map(linkResolver).map((link) => (
              <Button key={link.to || link.href} {...link} />
            ))}
          </Box>
        </GridRow.Col>
      )}
      <GridRow.Col
        gridColumn={hasDescription ? ["1 / -1", "7 / end"] : "1 / -1"}
        sx={{
          borderLeft: hasDescription ? [null, "1px solid"] : "",
          borderColor: "gray.3",
          pl: hasDescription ? [0, "gridGap"] : "gridGap",
          pr: hasDescription ? 0 : "gridGap",
          py: ["gridGap", 0],
          overflow: ["auto", "visible"],
        }}
      >
        <GridRow
          subGrid
          sx={{
            height: "100%",
            alignItems: "center",
            alignContent: "center",
            gridAutoColumns: ["220px", "auto"],
            gridAutoFlow: hasDescription ? ["column", "row"] : "column",
            gridTemplateColumns: hasDescription
              ? [
                  "none",
                  "repeat(2, [start] 1fr) [end]",
                  "repeat(3, [start] 1fr) [end]",
                ]
              : "none",
            columnGap: hasDescription ? [0, "gridGap"] : 0,
            mx: hasDescription ? 0 : -4,
          }}
        >
          {images.map((image) => (
            <Box
              key={image._key}
              sx={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                px: hasDescription ? [4, 0] : [4, 3, 4],
                "& + &": {
                  borderLeft: hasDescription
                    ? ["1px solid", "none"]
                    : "1px solid",
                  borderColor: "gray.3",
                },
              }}
            >
              <Image
                fluid={image.asset.fluid}
                sx={{
                  maxWidth: 175,
                }}
              />
            </Box>
          ))}
        </GridRow>
      </GridRow.Col>
    </GridRow>
  );
}
